import React, { useState, useEffect, useContext, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import store from "./store/index";
import { hasOwnerData, setOwnerDataSession, getOwnerDataSession, getOwnerAddress } from './Session';

import story from "./css/StoryPage.module.css";
import classes from "./css/HomePage.module.css";

import { useViewportScroll, useTransform, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import { useTranslation, Trans } from 'react-i18next';

function StoryPage() {
  const { t, i18n } = useTranslation();

  const address = getOwnerAddress();
  const dispatch = useDispatch();
  //下記、「style={{ opacity: inView1 ? 1 : 0, transition: 'opacity 2s' }}」
  //があるとWarning: Cannot update a component (`NavBar`) while rendering a different component (`StoryPage`)
  //のエラーが出るため、Navbarの更新を遅延させる
  useEffect(() => {
    // コンポーネントがマウントされた後にアドレスを設定
    dispatch({ type: 'SET_ADDRESS', address: address });
  }, [dispatch, address]);


  const [ref1, inView1] = useInView({
    triggerOnce: true, // 一度だけトリガーする
    threshold: 0.1, // 要素がビューポートに10%以上入ったらトリガーする
  });

  const [ref2, inView2] = useInView({
    triggerOnce: true, // 一度だけトリガーする
    threshold: 0.1, // 要素がビューポートに10%以上入ったらトリガーする
  });

  const [ref3, inView3] = useInView({
    triggerOnce: true, // 一度だけトリガーする
    threshold: 0.1, // 要素がビューポートに10%以上入ったらトリガーする
  });

  const [ref4, inView4] = useInView({
    triggerOnce: true, // 一度だけトリガーする
    threshold: 0.1, // 要素がビューポートに10%以上入ったらトリガーする
  });
  const [ref5, inView5] = useInView({
    triggerOnce: true, // 一度だけトリガーする
    threshold: 0.1, // 要素がビューポートに10%以上入ったらトリガーする
  });
  const [ref6, inView6] = useInView({
    triggerOnce: true, // 一度だけトリガーする
    threshold: 0.1, // 要素がビューポートに10%以上入ったらトリガーする
  });



  //inViewのdiv同士が十分に距離を取らないとちゃんと後続が機能しないっぽい。
  return (
    <>
    <div id="top" className={classes.app}>
      <motion.div
        initial={{ opacity: 0, x: -100 }} // 初期状態（透明で右側にオフセット）
        animate={{ opacity: 1, x: 0 }} // 移動後の状態（デフォルト位置で表示）
        transition={{ duration: 1 }} // アニメーションの時間
      >
      <div className={story.top}>
        <h1>Story</h1>
        <p>Short History of Project monoNoFuT Background</p>
      </div>
      </motion.div>


    <main className={classes.content}>
      <div ref={ref1} style={{ opacity: inView1 ? 1 : 0, transition: 'opacity 2s' }}>
        <section className={story.section_storyimg}>
          <img className={story.city} alt="City" src="../img/story/city1.jpg" />
        </section>
      </div>

      <div ref={ref1} style={{ opacity: inView1 ? 1 : 0, transition: 'opacity 2s' }}>
        <section className={classes.section}>
          <h2 className={story.elementCBDC}>20XX年ーー</h2>
          <div className={story.sentence}>
            <p>
              ブロックチェーン技術を基盤としたCentral Bank Digital Currency（CBDC）が各国で相次いで発行され暗号資産が爆発的に普及。<br />
              全てのIDや個人情報は政府管理下のブロックチェーン上で管理評価されるようになった。<br />
            </p>
            <p>
              資産や権利はFungible Token（FT）、ステーブルトークン（ST）、Center Bank Degital Currency (CBDC) 、Non-Fungible Token（NFT）として管理されるに至る。<br />
            </p>
          </div>
        </section>
      </div>
    </main>

      <br /><br />
      <br /><br />
      <br /><br />
      <div className={story.arrowDiv}>
      <motion.img className={story.arrowImg}
        src="../img/icon/arrow_down.png"
        alt="arrow"
        initial={{ y: 0 }}
        animate={{ y: [-10, 10, -10] }}
        transition={{ duration: 1.5, repeat: Infinity }}
      />
      </div>
      <br /><br />
      <br /><br />
      <br /><br />


    <main className={classes.content}>
      <section className={classes.section}>
        <div ref={ref2} style={{ opacity: inView2 ? 1 : 0, transition: 'opacity 2s' }}>
        <h2 className={story.elementCBDC}>急成長する暗号資産取引所</h2>
          <div className={story.sentence}>
          <p>
            それに伴い、暗号資産取引所が急成長。<br />
            3つの超巨大取引所が世界経済をはじめ、政界さえもコントロールするに至る。
          </p>
          <p>
            暗号資産の普及に伴い電子的、物理的問わず犯罪が多発。<br />
            各社「顧客資産保護」の名目のもと、サイバー攻撃および物理的攻撃に対処する為サイボーグ部隊を編成。
          </p>
          </div>
        </div>
      </section>

      <section className={story.section_storyimg}>
        <div ref={ref2} style={{ opacity: inView2 ? 1 : 0, transition: 'opacity 2s' }}>
          <img className={story.city} alt="City" src="../img/story/cyborg.jpg" />
        </div>
      </section>
    </main>


      <br /><br />
      <br /><br />
      <br /><br />
      <div className={story.arrowDiv}>
      <motion.img className={story.arrowImg}
        src="../img/icon/arrow_down.png"
        alt="arrow"
        initial={{ y: 0 }}
        animate={{ y: [-10, 10, -10] }}
        transition={{ duration: 1.5, repeat: Infinity }}
      />
      </div>
      <br /><br />
      <br /><br />
      <br /><br />


    <main className={classes.content}>
      <section className={story.section_storyimg}>
        <div ref={ref3} style={{ opacity: inView3 ? 1 : 0, transition: 'opacity 2s' }}>
          <img className={story.city} alt="City" src="../img/story/floatingbits.png" />
        </div>
      </section>

      <section className={classes.section}>
        <div ref={ref3} style={{ opacity: inView3 ? 1 : 0, transition: 'opacity 2s' }}>
        <h2 className={story.elementCBDC}>中央集権特化型株式会社 FloatingBits</h2>
          <div className={story.sentence}>
          <p>
            最も攻撃的な中央集権型組織のFloatingBits。
            <br />
            <br />
            世界最大の顧客資産とサイボーグ部隊を持つ。
            <br />
            『怪物』と呼ばれる異次元の能力を持つサイボーグ個体が存在すると噂される。
            <br />
            サイボーグによる物理的解決手段を多用し、裏社会さえ畏怖する取引所。
          </p>
          </div>
        </div>
      </section>
    </main>


      <br /><br />
      <br /><br />
      <br /><br />
      <div className={story.arrowDiv}>
      <motion.img className={story.arrowImg}
        src="../img/icon/arrow_down.png"
        alt="arrow"
        initial={{ y: 0 }}
        animate={{ y: [-10, 10, -10] }}
        transition={{ duration: 1.5, repeat: Infinity }}
      />
      </div>
      <br /><br />
      <br /><br />
      <br /><br />


    <main className={classes.content}>
      <section className={classes.section}>
        <div ref={ref4} style={{ opacity: inView4 ? 1 : 0, transition: 'opacity 2s' }}>
        <h2 className={story.elementCBDC}>分散型自立組織集合体 CryptoCurrent</h2>
          <div className={story.sentence}>
          <p>
            世界最大級のDAOを誇るCryptoCurrent。
            <br />
            <br />
            世界最大のDEX。顧客数はFloatingBits社よりも多い。
            <br />
            サイボーグ部隊もコミュニティーメンバーで構成される。
            <br />
            物理戦闘力はFloatingBits社に劣るがサイバー戦は得意。
          </p>
        </div>
        </div>
      </section>

      <section className={story.section_storyimg}>
        <div ref={ref4} style={{ opacity: inView4 ? 1 : 0, transition: 'opacity 2s' }}>
          <img className={story.city} alt="City" src="../img/story/cryptocurrent.png" />
        </div>
      </section>
    </main>


      <br /><br />
      <br /><br />
      <br /><br />
      <div className={story.arrowDiv}>
      <motion.img className={story.arrowImg}
        src="../img/icon/arrow_down.png"
        alt="arrow"
        initial={{ y: 0 }}
        animate={{ y: [-10, 10, -10] }}
        transition={{ duration: 1.5, repeat: Infinity }}
      />
      </div>
      <br /><br />
      <br /><br />
      <br /><br />


    <main className={classes.content}>
      <section className={story.section_storyimg}>
        <div ref={ref5} style={{ opacity: inView5 ? 1 : 0, transition: 'opacity 2s' }}>
          <img className={story.city} alt="City" src="../img/story/moonbits.png" />
        </div>
      </section>

      <section className={classes.section}>
        <div ref={ref5} style={{ opacity: inView5 ? 1 : 0, transition: 'opacity 2s' }}>
        <h2 className={story.elementCBDC}>分散型自立式株式会社 CryptoCurrent</h2>
          <div className={story.sentence}>
            <p>
            そして新興勢力のMoonBit。
            <br />
            <br />
            中央集権型と非中央集権型のハイブリッド取引所。
            <br />
            創業者はNFTクリエイターだったともスーパーエンジニアだったとも言われる。
            <br />
            ユーザーコミュニティー規模が世界一で非常に活発。
            <br />
            サイボーグは上記2社に比べると数は劣るが個体が非常に優秀。
            </p>
          </div>
        </div>
      </section>
    </main>


      <br /><br />
      <br /><br />
      <br /><br />
      <div className={story.arrowDiv}>
      <motion.img className={story.arrowImg}
        src="../img/icon/arrow_down.png"
        alt="arrow"
        initial={{ y: 0 }}
        animate={{ y: [-10, 10, -10] }}
        transition={{ duration: 1.5, repeat: Infinity }}
      />
      </div>
      <br /><br />
      <br /><br />
      <br /><br />


    <main className={classes.content}>
      <section className={classes.section}>
        <div ref={ref6} style={{ opacity: inView6 ? 1 : 0, transition: 'opacity 2s' }}>
          <div className={story.sentence}>
          <p>
            <br />
            <br />
            各社間抗争は日々エスカレート。<br />
            激化の一途を辿っていたーー
            <br />
            <br />
          </p>
        </div>
        </div>
      </section>

      <section className={story.section_storyimg}>
        <div ref={ref6} style={{ opacity: inView6 ? 1 : 0, transition: 'opacity 2s' }}>
          <img className={story.city} alt="City" src="../img/story/storylast.png" />
        </div>
      </section>
    </main>


      <br /><br />
      <br /><br />
      <br /><br />
    </div>

    </>
  );
}
export default StoryPage;
