
const { Alchemy, Network } = require("alchemy-sdk");

export const MONONOFU_WALLPAPERS = [
{
	nftid:'001',
	img: 'https://i.seadn.io/gae/n5NMZe8Eqgqon9KztWAvLxbDaFqwwaQiTHpupnmPts_z3Y8xNIlq6Ub9WwwiPn6HxWvo7rSEQTz2sb2GRfpYJxqKpvP3Ag3pSLg5Sg?auto=format&dpr=1&w=1000',
	pfpimg:'https://i.seadn.io/gae/QXWlzuIVrouxCKnLQz195v6CEqTzsjLf0zF5rseeEEljqCM5HepKHdRj0ycOve6lDP5VaO1GPZMaA5gc1QKB7mibnIfOR1gT5ZuDJA',
	wallpaper:[
		'/img/wallpaper/tsukinoto_pfp.png',
		'/img/wallpaper/tsukinoto_12k.png',
	]
},
{
	nftid:'003',
	img: 'https://i.seadn.io/gae/eP2VKvHK3CJ1nMQThCf7VLc4gl5-c6Vcd_vQA3CcR1qGRUYxwWr0TzgtgJd98A2F0f3spWDzCtkczYmGcLSC41-N26cZboINWTYMuA?auto=format&dpr=1&w=1000',
	pfpimg:'https://i.seadn.io/gae/Hp036sVk1J_Z7qyxK5kGG82gwZCVULZWfFo98RIHA7yjwie1Gr8MI7K-3MXMMsihu3W-Z9vJSKe4RHI5sdTjFmuOD5BNuJcNe9ZBVg',
	wallpaper:[]
},
{
	nftid:'004',
	img: 'https://i.seadn.io/gcs/files/eaaaa04518ffa84f19c0185e3f257b90.png',
	pfpimg:'https://i.seadn.io/gcs/files/00b530260b58c6a9423613300e45647f.png',
	wallpaper:[]
},
{
	nftid:'005',
	img: 'https://i.seadn.io/gcs/files/7802bb934f364b7bd2b41cca8b1f0f7f.png?auto=format&dpr=1&w=1000',
	pfpimg:'https://i.seadn.io/gcs/files/d0d009ca200747c812e553413dcc8241.png',
	wallpaper:[]
},
{
	nftid:'006v1',
	img: 'https://i.seadn.io/gcs/files/9e165d0deae0dffadb1c29265f1c8599.png',
	pfpimg:'https://i.seadn.io/gcs/files/0a65a23c0de797e2255a7d401bd73a12.png',
	wallpaper:[]
},
{
	nftid:'006v2',
	img: 'https://i.seadn.io/gcs/files/0667199710b426591f018abba7323a28.png',
	pfpimg:'https://i.seadn.io/gcs/files/1ae8c09bee483ba04173f202de3f0357.png',
	wallpaper:[]
},
{
	nftid:'007v1',
	img: 'https://assets.manifold.xyz/optimized/888fa2dc701e16ec30b355f1d19c0b87d1b12192018308f15e8f1b3b49ac79de/w_1024.webp',
	pfpimg:'',
	wallpaper:[]
},
{
	nftid:'007v2',
	img: 'https://assets.manifold.xyz/image/upload/c_limit,q_80,w_1024/b47904f35fecfacf4c23c047591df6c0b07947eebb3e74f562bd6e71f6d35974.webp',
	pfpimg:'https://assets.manifold.xyz/image/upload/c_limit,q_80,w_1024/b47904f35fecfacf4c23c047591df6c0b07947eebb3e74f562bd6e71f6d35974.webp',
	wallpaper:[]
},
{
	nftid:'009v1',
	img: '/img/wallpaper/sp_009v1_3k_final.png',
	pfpimg:'/img/wallpaper/sp_009v1_pfp2_final.png',
	wallpaper:[]
},
{
	nftid:'eq001',
	img: 'https://i.seadn.io/gcs/files/eb8752f5d030a1ea9a2ea64aa8fa5f5b.png',
	pfpimg:'https://i.seadn.io/gcs/files/eb8752f5d030a1ea9a2ea64aa8fa5f5b.png',
	wallpaper:[]
}
];