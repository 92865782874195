import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { useSelector, useDispatch } from "react-redux";
import reducer from './store/index'
import { hasOwnerData, setOwnerDataSession, getOwnerDataSession, getOwnerAddress, setOwnerAddress, setOwnerStatus, isNFTOwner, setRegionSession } from './Session';
import { getAllMNFTStatus } from './Utils';

import Modal from './Modal';

import infTxt from "./css/InfinitTxtLoop.module.css";
import story from "./css/StoryPage.module.css";
import classes from "./css/HomePage.module.css";
import btn from "./sass/button.scss";

import { useViewportScroll, useTransform, motion } from 'framer-motion';

import { useTranslation, Trans } from 'react-i18next';

export async function getAccount() {  
  try {
      const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
      if (account.length > 0) {
          console.log(account[0]);
          return account[0];
//          return window.ethereum.selectedAddress;
      } else {
          return "";
      }
  } catch (err) {
      if (err.code === 4001) {
          // EIP-1193 userRejectedRequest error
          // If this happens, the user rejected the connection request.
          console.log('Please connect to MetaMask.');
          alert(err.code + ', ' + err.message);
      } 

      //metamask activated and canceled
      else if (err.code === -32002) {
          console.error(err);
          alert(err.code + ', ' + err.message + "\nMetamaskロック解除後Loginボタンをクリックしてください。");
      }
      else {
          console.error(err);
          alert(err.code + ', ' + err.message);
      }
      return "";
  }
}

export async function initializeAccount (navigate, dispatch, setBtnDisable) {
  if (typeof window.ethereum == 'undefined') {
    console.log('Please install Metamask.');
    alert('Please install Metamask.');
    return ;
  }

  setBtnDisable(true);

  const tmpAccount = await getAccount();
  if (tmpAccount !== "") {
    setOwnerAddress(tmpAccount);
    dispatch({type: 'SET_ADDRESS', address: tmpAccount});

    await getAllMNFTStatus(tmpAccount);

    if(isNFTOwner()) {
      setOwnerStatus(true);
      navigate("/");
      return ;
    }
    else {
      setOwnerStatus(false);
      navigate("/");
    }
  }
  setBtnDisable(false);
}

function changeLang(i18n) {
  if(i18n.language === 'en') {
    i18n.changeLanguage('ja'); //更新のトリガー
    setRegionSession('ja');
  }
  else {
    i18n.changeLanguage('en');
    setRegionSession('en');
  }
}


function Login() {
  const { t, i18n } = useTranslation();

  const[btnDisable, setBtnDisable] = useState(false);

  const navigate = useNavigate();
//  const address = useSelector((state) => state.address);
  const address = getOwnerAddress();
  const dispatch = useDispatch();
  dispatch({type: 'SET_ADDRESS', address: address});


  return (
    <>
    <div>
      <motion.div
        initial={{ opacity: 0, x: -100 }} // 初期状態（透明で右側にオフセット）
        animate={{ opacity: 1, x: 0 }} // 移動後の状態（デフォルト位置で表示）
        transition={{ duration: 1 }} // アニメーションの時間
      >
        <div className={story.top}>
        <h1>Login</h1>
        <p>Login to Enter Project monoNoFuT World</p>
        </div>
      </motion.div>

      <section>
          <section className={classes.sentence100_title}>
            <span className={`${infTxt.electro}`} style={{ fontSize: '1.5rem' }}><Button className="pulse" onClick={() => initializeAccount(navigate, dispatch, setBtnDisable)} disabled={btnDisable} >Login</Button></span>
            <span> -{t('header.signin')}- </span>
          </section>
{/*
            <Button className="pulse" onClick={() => changeLang(i18n)} disabled={btnDisable} >change lang</Button>
*/}
          <div className={classes.grid_container_one}>
            <div className={classes.grid_item_one}>
              ログインすることでオーナー専用の機能が使えます。<br />
              オーナー様の所有するサイボーグとの面談や、専用ギャラリーが閲覧可能！
            </div>
          </div>
        </section>

    </div>
    </>
  );
}

export default Login;