import React, { useState, useEffect, useRef } from 'react';
import { FaSquareXTwitter, FaArrowUpLong, FaInstagram, FaSquareThreads, FaBluesky } from "react-icons/fa6";
import { Container, Navbar, Nav } from 'react-bootstrap';
//import ScrollTo from "react-scroll-into-view";


const Footer = () => {
  //各ページにid="top"を設置
  const handleClickScroll = () => {
//    document.documentElement.scrollTop = 0;
    const element = document.getElementById('top');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };


  return (
    <Navbar bg="dark" variant="dark">
    <Container>
    <Navbar.Collapse className="justify-content-center">
      <Nav.Link onClick={handleClickScroll} style={{marginRight: 3}}><FaArrowUpLong size={20} color="white" /></Nav.Link>
      <Nav.Link href="https://twitter.com/heppokopu" target="_blank" rel="noreferrer" style={{marginRight: 5}}>
        <FaSquareXTwitter size={24} color="white" />
      </Nav.Link>
      <Nav.Link href="https://bsky.app/profile/heppokopu.bsky.social" target="_blank" rel="noreferrer" style={{marginRight: 5}}>
        <FaBluesky size={24} color="white" />
      </Nav.Link>
      <Nav.Link href="https://www.instagram.com/heppokopu/" target="_blank" rel="noreferrer" style={{marginRight: 5}}>
        <FaInstagram size={24} color="white" />
      </Nav.Link>
      <Nav.Link href="https://www.threads.net/@heppokopu" target="_blank" rel="noreferrer" style={{marginRight: 5}}>
        <FaSquareThreads size={24} color="white" />
      </Nav.Link>
      </Navbar.Collapse>
      <Navbar.Text className="justify-content-end" style={{ fontSize: 12 }}>
        (c) 2024 Project monoNoFuT
      </Navbar.Text>
    </Container>
  </Navbar>
  );
};

export default Footer;
