import { React, useState } from 'react';
import classes from './css/Modal.module.css';

import Button from 'react-bootstrap/Button';

import {MONONOFU_WALLPAPERS} from './resources/WallpaperData.js';

import { Card, Container, Row, Col } from 'react-bootstrap';

import nvbarclass from "./css/NavBar.module.css";
import story from "./css/StoryPage.module.css";

function MModal({ isOpen, handleCloseModal, nft }) {
  if (!isOpen) {
    return null; // isOpenがfalseの場合はモーダルを表示しない
  }




  const getWallpaperImgs = (nftid) => {
    return MONONOFU_WALLPAPERS.find((wdata) => wdata.nftid === nftid);
  };

  const crntWData = getWallpaperImgs(nft.nftid);

  return (
    <div className={classes.modaloverlay}>
      <div className={classes.modalcontent}>
        <Button className={classes.closemodal} onClick={handleCloseModal}>Close</Button>
        <div>{nft.nftid}</div>
        <div>{nft.name}</div>
        <section className={story.aboutpage_title}>
        <h2 className={`${nvbarclass.electro} ${nvbarclass.title}`}><span style={{color: 'white'}}>Secret Gallery</span></h2>
        </section>

        <div className={classes.modalwallpaperType1}>
        <Container>
  <Row style={{ marginTop: '5px' }}>
    <Col key={crntWData.nftid}>

    <div className={classes.modalCardGrid}>
        <Card style={{ marginRight: '5px', marginTop: '5px' }}>
          <a href={crntWData.img} target="_blank">
            <Card.Img variant="top" style={{ maxWidth: '95%' }} src={crntWData.img} />
          </a>
          <Card.Body>
            <Card.Title>Character</Card.Title>
          </Card.Body>
        </Card>

        {crntWData.pfpimg !='' && (
        <Card style={{ marginRight: '5px', marginTop: '5px' }}>
          <a href={crntWData.pfpimg} target="_blank">
            <Card.Img variant="top" style={{ maxWidth: '95%' }} src={crntWData.pfpimg} />
          </a>
          <Card.Body>
            <Card.Title>PFP</Card.Title>
          </Card.Body>
        </Card>
        )}
      </div>

    </Col>
  </Row>
</Container>
        </div>
      </div>
    </div>
  );
}

export default MModal;
