import React from 'react';
import Slider from 'react-slick';

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  arrows: false
};

export const ImageSlider = ({ images }) => {
  return (
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`Image ${index + 1}`} style={{ width: '100%', height: '100%', margin: '0 auto' }} />
          </div>
        ))}
      </Slider>

  );

};
