import { createStore } from "redux";

const initialState = {
  address: "",
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
  case 'SET_ADDRESS':
    return { address: action.address};

  default:
    return state;
  }
};

const store = createStore(reducer);

export default store;
