import { React, useState } from 'react';
import classes from './css/Modal.module.css';

import Button from 'react-bootstrap/Button';

import {MONONOFU_WALLPAPERS} from './resources/WallpaperData.js';

import {getUserNFTOwningStatus, 
NFT_NOTOWN, NFT_CHAR_ONLY, NFT_RELATE_ONLY, NFT_CHAR_AND_RELATE, NFT_OWN_OTHERNFT} from './Session';



function OneOnOneModal({ isOpen, handleCloseModal, nft }) {
  if (!isOpen) {
    return null; // isOpenがfalseの場合はモーダルを表示しない
  }

  const nftStat = getUserNFTOwningStatus(nft);

  const novalUrl = "/novel/"+nft.oneOn1+"/index.html?nftStat=" + nftStat;

  return (
  <div className={classes.modaloverlay}>
    <div className={classes.modalcontent}>
      <Button className={classes.closemodal} onClick={handleCloseModal}>Close</Button>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <iframe
            id="frame_tyrano"
            style={{width: '93vw', height: '93vh', maxWidth: '640px', maxHeight: '960px'}}
            src={novalUrl}
          ></iframe>
      </div>
    </div>
  </div>
  );
}

export default OneOnOneModal;
