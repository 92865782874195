import React, { useEffect, useContext, useState } from 'react';

import styles from "./css/overlap.module.css";

function Test() {


  return (
    <div>
      <div className={styles.container}>
        <div className={styles.element1}>要素1</div>
        <div className={styles.element2}>要素2</div>
      </div>
    </div>
  );
}

export default Test;