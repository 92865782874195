import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from "react-redux";
import store from "./store/index";
import { hasOwnerData, setOwnerDataSession, getOwnerDataSession, getOwnerAddress, getOwnerStatus } from './Session';
import Button from 'react-bootstrap/Button';
import { ImageSlider } from "./ImageSlider";
import { Timeline } from 'react-twitter-widgets';

import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { motion, AnimatePresence } from "framer-motion";

import {MONONOFU_CHARACTERS, NFTTYPE_CHAR} from './resources/Data.js';

import classes from "./css/HomePage.module.css";
import "./sass/GlitchText.scss";
import infTxt from "./css/InfinitTxtLoop.module.css";
import ov from "./css/overlap.module.css";

import { useTranslation, Trans } from 'react-i18next';

import { XEmbed, TwitterTimelineEmbed } from 'react-social-media-embed';

import { HeadBlock } from './components/HeadBlock.js';

function HomePage() {
  const { t, i18n } = useTranslation();
  

  const tmpAddress = getOwnerAddress();
  const address = useSelector((state) => tmpAddress);
  const dispatch = useDispatch();
  dispatch({type: 'SET_ADDRESS', address: address});

  const images = MONONOFU_CHARACTERS.filter(item => item.type === NFTTYPE_CHAR).map((item) => item.img);

  return (
    <>
    {/* set thte meta data */}
    <HeadBlock title={t('header.home.title')} description={t('header.home.description')} path={t('header.home.path')} keywords={t('header.keywords')} abstracts={t('header.abstracts')} author={t('header.author')} />
    
    <div id="top" className="container-fluid">
      <div style={{ justifyContent: 'center' }} >
        <div className={ov.container}>
          <div className={ov.element1}>
            <div style={{ display: 'flex', justifyContent: 'center' }} >
              <img className={classes.bottom} alt="top" src="../img/bg/rectangle-1.png" />
            <div className={` ${infTxt.fixed}`}>
              <span style={{fontStyle: 'italic'}}>和 × SF × ブロックチェーン。</span>
            </div>
            </div>
          </div>

          <div className={ov.element2}>
          </div>
        </div>
      </div>
      <br/>
      <br/>


      <main className={classes.content}>
        <motion.div
          initial={{ opacity: 0, x: -100 }} // 初期状態（透明で右側にオフセット）
          animate={{ opacity: 1, x: 0 }} // 移動後の状態（デフォルト位置で表示）
          transition={{ duration: 3 }} // アニメーションの時間
        >
        <section className={classes.section}>
        <h1 className="neon">Project monoNoFuT</h1>
        <p style={{ fontSize: '1.0em' }}>-The Project mints near-future Cyborg NFTs-</p>
        </section>

        <section className={classes.section} style={{ fontSize: '1.0em' }}>
        <h2 className="neon" style={{ fontSize: '1.3em' }}>世界背景</h2>
        <p>
          全ての情報はブロックチェーンにて管理され、<br />
          暗号資産が主流になった近未来東京、ネオ・エド。<br /><br />
          暗号資産を取扱う取引所が経済を牛耳るようになった。<br /><br />
          プロジェクトモノノフは、企業間抗争が激化する中懸命に生きるサイボーグ達の物語ーー
        </p>
        </section>
        
        </motion.div>


        <motion.div
          initial={{ opacity: 0, x: 100 }} // 初期状態（透明で右側にオフセット）
          animate={{ opacity: 1, x: 0 }} // 移動後の状態（デフォルト位置で表示）
          transition={{ duration: 2 }} // アニメーションの時間
        >
        <section className={classes.section_black}>
        <a href="/characters">
          <div style={{ width: '400px', height: '400px', margin: '0 auto' }}>
          <ImageSlider images={images}/>
          </div>
        </a>
        </section>
        </motion.div>

      </main>

      <div style={{ justifyContent: 'center' }} >
        <div className={ov.container}>
          <div className={ov.element1}>
            <div style={{ display: 'flex', justifyContent: 'center' }} >
              <img className={classes.bottom} alt="top" src="../img/bg/neoedo_from_sky.png" />
            </div>
          </div>

          <div className={ov.element2}>
            <div className={`${infTxt.electro} ${infTxt.scroll}`}>
              <span style={{fontStyle: 'italic'}}><p>Project mononofut is about cyborg girls working at the Crypto Exchanges in the near future Tokyo called Neo-Edo. </p></span>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
      <main>
        <motion.div
          initial={{ opacity: 0, x: -100 }} // 初期状態（透明で右側にオフセット）
          animate={{ opacity: 1, x: 0 }} // 移動後の状態（デフォルト位置で表示）
          transition={{ duration: 2 }} // アニメーションの時間
        >

<section style={{ width: '100%' }}>
  <section className={classes.sentence100_title}>
    <h2 className={`${infTxt.electro}`} style={{ fontSize: '1.5rem', display: 'inline-block' }}>What's New</h2>
    <span> -What's New-</span>
  </section>
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
      {/* XEmbed width max is 550 FIXED. changes will deform the structure. */}
      <XEmbed url="https://x.com/heppokopu/status/1829900241447109016" width={550} style={{marginRight: '5px'}}/>

      
      {/* 
      if need to post multiple Tweet, it may be done as follows 
      <XEmbed url="https://x.com/heppokopu/status/1792714276316340625" width={550}  style={{marginRight: '5px'}}/>
      */}
      </div>
    </div>
  </section>
        </motion.div>
      </main>
      <br/>


      <main>
        <motion.div
          initial={{ opacity: 0, x: -100 }} // 初期状態（透明で右側にオフセット）
          animate={{ opacity: 1, x: 0 }} // 移動後の状態（デフォルト位置で表示）
          transition={{ duration: 2 }} // アニメーションの時間
        >

        <section>
          <section className={classes.sentence100_title}>
            <h2 className={`${infTxt.electro}`} style={{ fontSize: '1.5rem', display: 'inline-block' }}>Roadmap</h2>
            <span> -ロードマップ-</span>
          </section>

          <div className={classes.grid_container}>
            <div className={classes.grid_item}>
              <h3 className={classes.grid_item_title}>1. NFT鋳造</h3>
              <p>
                サイボーグのNFT鋳造<br/>
                ネオ・エドに存在する企業サイボーグ達のNFTを数量限定で鋳造・提供
              </p>
            </div>
            <div className={classes.grid_item}>
              <h3 className={classes.grid_item_title}>2. Dappsの提供</h3>
              <p>
                Dappsの作成、オーナー専用機能の提供<br/>
                オーナーへのGiveaway
              </p>
            </div>
            <div className={classes.grid_item}>
              <h3 className={classes.grid_item_title}>3. グッズの提供</h3>
              <p>
                リアルワールドアセット（RWA）の提供
              </p>
            </div>
          </div>
        </section>
        </motion.div>
        <br />
        <br />
      </main>
    </div>

    </>
  );
}

export default HomePage;