import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from "react-redux";
import store from "./store/index";
import { hasOwnerData, setOwnerDataSession, getOwnerDataSession, getOwnerAddress } from './Session';

import story from "./css/StoryPage.module.css";
import classes from "./css/HomePage.module.css";
import fontclasses from "./css/NavBar.module.css";

import { useViewportScroll, useTransform, motion, AnimatePresence, useAnimation } from 'framer-motion';

import { useInView } from 'react-intersection-observer';

import { useTranslation, Trans } from 'react-i18next';


const DelayedText = ({ children, delayInSeconds }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // 渡された秒数後にisVisibleをtrueに設定する
    const timeoutId = setTimeout(() => {
      setIsVisible(true);
    }, delayInSeconds * 1000);

    // コンポーネントがアンマウントされたらタイマーをクリア
    return () => clearTimeout(timeoutId);
  }, [delayInSeconds]);

  return (
    <>
      {(isVisible) && (children)}
    </>
  );
};

//


function AboutPage() {
  const { t, i18n } = useTranslation();

  const address = getOwnerAddress();
  const dispatch = useDispatch();
  dispatch({type: 'SET_ADDRESS', address: address});


  return (
    <>
    <div id="top" >
      <motion.div
        initial={{ opacity: 0, x: -100 }} // 初期状態（透明で右側にオフセット）
        animate={{ opacity: 1, x: 0 }} // 移動後の状態（デフォルト位置で表示）
        transition={{ duration: 1 }} // アニメーションの時間
      >
      <div className={story.top}>
      <h1>About Page</h1>
      <p>What is Project monoNoFuT?</p>
      </div>
    </motion.div>

    <DelayedText delayInSeconds={0.5}>
      <motion.div
        initial={{ opacity: 0, x: -100 }} // 初期状態（透明で右側にオフセット）
        animate={{ opacity: 1, x: 0 }} // 移動後の状態（デフォルト位置で表示）
        transition={{ duration: 1 }} // アニメーションの時間
      >
        <section className={story.aboutpage_title}>
        <span><h2 className={`${fontclasses.title} ${fontclasses.titlegradient}`}>コンセプト</ h2></span>
        </section>
      </motion.div>

      <div className={story.aboutpage_text}>
        <motion.div
            initial={{ opacity: 0, x: 200 }} // 初期状態（透明で右側にオフセット）
            animate={{ opacity: 1, x: 0 }} // 移動後の状態（デフォルト位置で表示）
            transition={{ duration: 1 }} // アニメーションの時間
        >
          <section className={story.section_sentence}>
          <p>
          「Project monoNoFuT（プロジェクト・モノノフ）」は近未来東京を舞台に、企業で活躍するサイボーグ達のNFTコレクションになります。<br /><br />
          コンセプトは「和 × SF × ブロックチェーン」。<br /><br />
          NFTの中に必ず「和」の要素が含まれます。<br />
          全てのサイボーグは背景以外AIを使用しておりません。<br />
          マニキュアに至るまで細部に拘っており、各サイボーグ毎に深いオリジナル背景ストーリーと、ゲーム感覚で楽しめるビジュアルノベルを作成しています。<br />
          </p>
          </section>
        </motion.div>
      </div>
    </DelayedText>

  <br />

  <DelayedText delayInSeconds={1}>
    <motion.div
      initial={{ opacity: 0, x: -100 }} // 初期状態（透明で右側にオフセット）
      animate={{ opacity: 1, x: 0 }} // 移動後の状態（デフォルト位置で表示）
      transition={{ duration: 1 }} // アニメーションの時間
    >
      <section className={story.aboutpage_title}>
      <span><h2 className={`${fontclasses.title} ${fontclasses.titlegradient}`}>クリエイターについて</h2></span>
      </section>
    </motion.div>

    <div className={story.aboutpage_text}>
      <motion.div
          initial={{ opacity: 0, x: 200 }} // 初期状態（透明で右側にオフセット）
          animate={{ opacity: 1, x: 0 }} // 移動後の状態（デフォルト位置で表示）
          transition={{ duration: 1 }} // アニメーションの時間
      >
        <section className={story.section_sentence}>
          <p>
          <b>ぽこぷぅ（創立者兼アーティスト兼プログラマー）</b><br />
          <br />
          暗号資産、NFT、web3住人<br />
          TOKYOWeb3FESで入賞、Nコレ北海道、ブロックチェーンEXPO、その他様々なイベントに出展。<br />
          </p>
          <a href="https://twitter.com/heppokopu" target="_blank">X アカウントへ</a>
        </section>
      </motion.div>
    </div>
  </DelayedText>

<br />

<DelayedText delayInSeconds={1}>
    <motion.div
      initial={{ opacity: 0, x: -100 }} // 初期状態（透明で右側にオフセット）
      animate={{ opacity: 1, x: 0 }} // 移動後の状態（デフォルト位置で表示）
      transition={{ duration: 1 }} // アニメーションの時間
    >
      <section className={story.aboutpage_title}>
        <span><h2 className={`${fontclasses.title} ${fontclasses.titlegradient}`}>NFTオーナーの権利</h2></span>
      </section>
    </motion.div>

    <div className={story.aboutpage_text}>
      <motion.div
          initial={{ opacity: 0, x: 200 }} // 初期状態（透明で右側にオフセット）
          animate={{ opacity: 1, x: 0 }} // 移動後の状態（デフォルト位置で表示）
          transition={{ duration: 1 }} // アニメーションの時間
      >
        <section className={story.section_sentence}>
          <p>
          現在Project monoNoFuTオーナーは下記の権利を所有します：<br />
          1. 保有するサイボーグと<a href="/characters">面談（ビジュアルノベル形式）</a>を行う事ができます（ログイン後キャラクター一覧にて）<br />
          2. 保有するサイボーグの<a href="/characters">シークレットギャラリー</a>から高画質画像や壁紙をダウンロード可能です（ログイン後キャラクター一覧にて）<br />
          3. 販売しているNFTを20%OFFで購入する権利
          </p>
        </section>
      </motion.div>
    </div>
  </DelayedText>

<br />

  <DelayedText delayInSeconds={1.5}>
    <motion.div
      initial={{ opacity: 0, x: -100 }} // 初期状態（透明で右側にオフセット）
      animate={{ opacity: 1, x: 0 }} // 移動後の状態（デフォルト位置で表示）
      transition={{ duration: 1 }} // アニメーションの時間
    >
      <section className={story.aboutpage_title}>
        <span><h2 className={`${fontclasses.title} ${fontclasses.titlegradient}`}>面談（ビジュアルノベル）について</h2></span>
      </section>
    </motion.div>

    <div className={story.aboutpage_text}>
      <motion.div
          initial={{ opacity: 0, x: 200 }} // 初期状態（透明で右側にオフセット）
          animate={{ opacity: 1, x: 0 }} // 移動後の状態（デフォルト位置で表示）
          transition={{ duration: 1 }} // アニメーションの時間
      >
        <section className={story.section_sentence}>
          <p>
          Project monoNoFuTのNFTを所持している場合、そのNFTに関係するキャラクターの面談を行う事ができます。<br />
          面談はビジュアルノベル形式で進みます。<br />
          NFTを所持していなくてもゲームはプレイ可能ですが、所有している場合より多くのシナリオをお楽しみいただけます。<br />
          シナリオは随時更新・追加し、X（旧Twitter）にて告知します！<br />
          </p>
          <p>
          以下、現時点での遊べるシナリオと条件：<br />
          1. NFTなし：プロローグ ＋ 1話まで<br />
          2. キャラクターに関連しないNFT所持：プロローグ ＋ 2話まで<br />
          3. キャラクターに関連するNFT（関連する装備、PFP、関連キャラNFT等）所持：プロローグ ＋ 3話まで<br />
          4. キャラクターNFT所持：所持しているキャラクターのシナリオ全て<br />
          </p>
          <a href="/characters">サイボーグ一覧へ</a>
        </section>
      </motion.div>
    </div>
  </DelayedText>

<br />

  <DelayedText delayInSeconds={2}>
    <motion.div
      initial={{ opacity: 0, x: -100 }} // 初期状態（透明で右側にオフセット）
      animate={{ opacity: 1, x: 0 }} // 移動後の状態（デフォルト位置で表示）
      transition={{ duration: 1 }} // アニメーションの時間
    >
      <section className={story.aboutpage_title}>
        <span><h2 className={`${fontclasses.title} ${fontclasses.titlegradient}`}>販売しているNFTを20%OFFで購入する権利</h2></span>
      </section>
    </motion.div>

    <div className={story.aboutpage_text}>
      <motion.div
          initial={{ opacity: 0, x: 200 }} // 初期状態（透明で右側にオフセット）
          animate={{ opacity: 1, x: 0 }} // 移動後の状態（デフォルト位置で表示）
          transition={{ duration: 1 }} // アニメーションの時間
      >
        <section className={story.section_sentence}>
          <p>
          Project monoNoFuTのNFTを1つでも所有している場合、20%OFFで販売中のNFTを購入できます。<br />
          新しいNFTを購入後、<a href="https://twitter.com/heppokopu" target="_blank">当プロジェクトオーナーにDM</a>してください。<br />
          確認の為、下記の情報をお伝えください：<br />
          <br />
          1. 購入したNFTの名前（例：那由多、洛叉、等）<br />
          2. 購入したNFTを保有しているアドレス<br />
          3. 過去に購入したNFTを保有しているアドレス<br />
          </p>
          <p>
          (2)と(3)は一致している必要があります（NFTを別のアドレスに送信してしまっていると確認ができません）。<br />
          当方で確認後、問題なければ購入したNFTを取得したアドレスに20%還元いたします。<br />
          確認が出来ない場合は返金できかねますので予めご了承ください。<br />
          </p>
          <a href="/characters">サイボーグ一覧へ</a>
          <br />
          <span className={classes.warningText}>※フィッシング、詐欺行為等、悪質と判断される行為はお控えください。</span>
        </section>
      </motion.div>
    </div>
  </DelayedText>

<br />


    </div>

    </>
  );
}

export default AboutPage;