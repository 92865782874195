import { VFC } from 'react';
import { Helmet } from 'react-helmet-async';
import { getCreatedDateForMetaTag } from '../Utils';

type Props = {
  title?: string;
  description?: string;
  path?: string;
  keywords?: string;
  abstracts?: string;
  author?: string;
  url?: string;
};

/*
各ページの metaデータを変更する。
変更の際、i18nの各リージョンのjsonファイルを参照して変更する。
*/
export const HeadBlock: React.FC<Props> = (props) => {
    const { title, description, path, keywords, abstracts, author, url } = props;
    const crntDate = getCreatedDateForMetaTag();
    return (
      <Helmet>
        <title>{title || 'Project monoNoFuT'}</title>
        <meta name="description" content={description || 'This is Project monoNoFuT. The NFT Project that is about the cyborgs in the Crypto exchanges in NEO-EDO.'} />
{/*        <link rel="canonical" href={`${url || 'https://mononofut.com'}/${path || ''}`} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="keywords" content={keywords || 'NFT,crypto,web3,cyborg,near-future,game,dapps'} />

        <meta property="og:title" content={title || 'Project monoNoFuT'} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url || 'https://mononofut.com'} />
        <meta property="og:description" content={description || 'This is Project monoNoFuT. The NFT Project that is about the cyborgs in the Crypto exchanges in NEO-EDO.'} />
        <meta property="og:site_name" content="Project monoNoFuT" />

        <meta http-equiv="Content-Script-Type" content="text/JavaScript" />
        <meta http-equiv="Content-Style-Type" content="text/css" />

        <meta name="abstract" content={abstracts || 'The NFT Project set in the near-future parallel world, Neo-Edo, where Cyborgs strive to survive.'} />
        <meta name="author" content={author || 'Pokopu'} />
        <meta name="owner" content={author || 'Pokopu'} />
        <meta name="designer" content={author || 'Pokopu'} />
        <meta name="creation date" content={crntDate} />
        <meta name="copyright" content="Copyright (C) Project monoNoFuT" />
        <meta name="country code" scheme="81" />
        <link rel="shortcut icon" href={`${url || 'https://mononofut.com'}/favicon.ico`} />
        <link rel="start" href={url || 'https://mononofut.com'} />
        <meta name="distribution" content="Global" />
        <meta name="robots" content="INDEX,FOLLOW" />
        <meta name="robots" content="NOARCHIVE" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta name="robots" content="NOIMAGEINDEX" />
        <meta name="robots" content="NOIMAGECLICK" />
        <meta name="revisit-after" content="5 days" />
        <meta name="google" content="notranslate" />
        <meta name="thumbnail" content={`${url || 'https://mononofut.com'}/logo.png`} />
        <meta charset="utf-8" />
*/}
        {/* Bingクローラー用*/}
        <meta name="msvalidate.01" content="2C5A3817156A4EBF54701FE9BCF54179" />
      </Helmet>
    );
  };