import React, {useEffect, useState, createContext} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import classes from "./css/HomePage.module.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import NavBar from './NavBar';
import HomePage from './HomePage';
import AboutPage from './AboutPage';
import Login, {getAccount} from './Login';
import Characters from './Characters';
import StoryPage from './StoryPage';
import TestPage from './TestPage';
import Footer from './Footer';
import ShopPage from './ShopPage';
import { connect, useDispatch } from "react-redux";

import { useTranslation, Trans } from 'react-i18next';
import { getRegionSession } from './Session';

export const AccountContext = createContext();

const Chains = {
    1: "Mainnet",
    3: "Ropsten",
    4: "Rinkeby",
    42: "Kovan",
    1337: "Geth private chain(default )",
    61: "Ethereum Classic Mainnet",
    62: "Morden",
}



// export const handleAccountChanged = async (accountNo, setAccount, setChainId, setBtnStatus) => {
//     const account = await getAccount();
//     setAccount(account);
//     if(account === "") {
//       setBtnStatus(false);
//     }
//     else {
//       setBtnStatus(true);
//     }

//     const chainId = await getChainID();
//     setChainId(chainId);
// }

const getChainID = async () => {
    const chainId = await window.ethereum.request({ method: 'eth_chainId' });
    return parseInt(chainId);
}


function App() {
  //set the region infomation to SessionData
  const { i18n } = useTranslation();
  //when page has changed the region will set to default.
  //get the correct region info from SessionData and change it.
  //it might be better way to do this... need to change it to use createContext.
  const lng = getRegionSession();
  useEffect(() => { //ここで画面更新する際1度きりの更新を行う。
    if(i18n.language !== lng) {
      i18n.changeLanguage(lng);
    }
//    console.log("App: " + i18n.language);
  });



  const [account, setAccount] = useState("");
  const value = {
    account,
    setAccount,
  };
  // const [chainId, setChainId] = useState(0);
  // const [btnStatus, setBtnStatus] = useState(false);

  // const initializeAccount = async () => {
  //     const tmpAccount = await getAccount();
  //     if (tmpAccount != "") {
  //         handleAccountChanged(tmpAccount, setAccount, setChainId, setBtnStatus);
  //     }
  // };

  // const dispatch = useDispatch();
  // const setAddress = () => {
  //   dispatch({type: 'SET_ADDRESS', address: 'AAA'});
  // };



  return (
    <div>
      <NavBar />    
    <div className={classes.root}>

      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/login" element={<Login />} />
{/*           <Route path="/home" element={<HomePage  />} /> */}
          <Route path="/characters" element={<Characters  />} />
          <Route path="/story" element={<StoryPage  />} />
          <Route path="/shop" element={<ShopPage  />} />
          <Route path="/test" element={<TestPage  />} />
        </Routes>
      </Router>
    </div>
    <Footer />
    </div>
  );
}

export default App;
