import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from "react-redux";
import store from "./store/index";
import { hasOwnerData, setOwnerDataSession, getOwnerDataSession, getOwnerAddress } from './Session';

import story from "./css/StoryPage.module.css";
import classes from "./css/HomePage.module.css";
import fontclasses from "./css/NavBar.module.css";

import { useViewportScroll, useTransform, motion, AnimatePresence, useAnimation } from 'framer-motion';

import { useInView } from 'react-intersection-observer';

import { useTranslation, Trans } from 'react-i18next';


function ShopPage() {
  const { t, i18n } = useTranslation();

  const address = getOwnerAddress();
  const dispatch = useDispatch();
  dispatch({type: 'SET_ADDRESS', address: address});


  return (
    <>
    <div id="top" >
      <motion.div
        initial={{ opacity: 0, x: -100 }} // 初期状態（透明で右側にオフセット）
        animate={{ opacity: 1, x: 0 }} // 移動後の状態（デフォルト位置で表示）
        transition={{ duration: 1 }} // アニメーションの時間
      >
      <div className={story.top}>
      <h1>Cyborg NFT Shop</h1>
      <p>Currently On Sale!</p>
      </div>
    </motion.div>


    <br/>


<main className={classes.content_shop}>
  <motion.div
    initial={{ opacity: 0, x: -100 }} // 初期状態（透明で右側にオフセット）
    animate={{ opacity: 1, x: 0 }} // 移動後の状態（デフォルト位置で表示）
    transition={{ duration: 3 }} // アニメーションの時間
  >
    
  <section  className={classes.section_shopTitle_left}>
    {/* <iframe
        src="https://embed.ipfscdn.io/ipfs/bafybeigdie2yyiazou7grjowoevmuip6akk33nqb55vrpezqdwfssrxyfy/erc1155.html?contract=0x6CC6f8aDC123e7d50406F23c7a22E9DDfD10E7b0&chain=%7B%22name%22%3A%22Polygon+Mainnet%22%2C%22chain%22%3A%22Polygon%22%2C%22rpc%22%3A%5B%22https%3A%2F%2F137.rpc.thirdweb.com%2F%24%7BTHIRDWEB_API_KEY%7D%22%5D%2C%22nativeCurrency%22%3A%7B%22name%22%3A%22MATIC%22%2C%22symbol%22%3A%22MATIC%22%2C%22decimals%22%3A18%7D%2C%22shortName%22%3A%22matic%22%2C%22chainId%22%3A137%2C%22testnet%22%3Afalse%2C%22slug%22%3A%22polygon%22%2C%22icon%22%3A%7B%22url%22%3A%22ipfs%3A%2F%2FQmRNqgazYuxUa5WdddFPftTWiP3KwzBMgV9Z19QWnLMETc%22%2C%22width%22%3A2000%2C%22height%22%3A2000%2C%22format%22%3A%22png%22%7D%7D&clientId=e7670416ecb1ced493b1ca945acd9422&tokenId=1&theme=light&primaryColor=blue"
        width="100%"
        height="600px"
    ></iframe> */}
    <section className={classes.section_left}>
      <p style={{ fontSize: '1.0em' }}>
        <h2>-= 販売終了 =-</h2>
        ⛩️フェーズ2（有料ミント）<br />
        期間：2024/9/1 00:00:00 JST頃 〜 2024/9/7 23:59:59 JST頃 <br />
        <br />
        ※ 有料版をご購入の場合、焔のProfile PictureNFTと装備品NFTをプレゼントいたします。<br />
        * プレゼント配布は期間終了後　ご購入頂いたユーザーに纏めて配布いたします！
      </p>
      <img className={classes.nftshop} alt="pfp" src="../img/shop/sp_009v1_pfp2_final.png" />
      <img className={classes.nftshop} alt="weapon" src="../img/shop/sword_final_ad.png" />
    </section>
  </section>
  
  </motion.div>


  <motion.div
    initial={{ opacity: 0, x: 100 }} // 初期状態（透明で右側にオフセット）
    animate={{ opacity: 1, x: 0 }} // 移動後の状態（デフォルト位置で表示）
    transition={{ duration: 2 }} // アニメーションの時間
  >
  <section className={classes.section_shopTitle_right}>
  <img className={classes.nftshop} alt="top" src="../img/shop/sp_009v1_14k_final.jpg" />
    <section className={classes.section_shopTitle}>
      <h2>焔v1 -Homura-</h2>
    </section>
    
    <section className={classes.section_left}>
    <p style={{ fontSize: '1.0em' }}>
    冷酷無比、残虐非道。<br />
  反社会的組織すら震え上がると言われるFloatingBits社情報局。<br />
  従わない者は敵と見なし、排除する。<br />
  迅速かつ冷静な判断力、他を寄せ付けない圧倒的なカリスマ。<br />
  情報局局長である彼女に歯向かうということは、自らの命だけでなく、会社の存続、家族、友人の命さえも危険に晒すことになる…という事を自覚するべきだ。<br />
  <br />
  —-<br />
  <br />
  Cold-bloodedness and utter cruelty.<br />
  The FloatingBits Intelligence Bureau, said to make even antisocial organizations tremble.<br />
  Those who do not comply with them are seen as enemies and annihilated.<br />
  She has the swift and keen judgement and dominating charisma.<br />
  <br />
  One must be aware that to oppose her, the head of the Intelligence Bureau, is risking not only your own life but also the lives of your family, friends, and your company.<br />

    </p>
    </section>


  </section>


  </motion.div>

</main>



    <main>
    </main>


    </div>
    </>
  );
}

export default ShopPage;